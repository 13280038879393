import { defineStore } from "pinia";
//import MethodService from '../service / MethodService';

export const datePicker = defineStore({
  id: "datePicker",
  state: () => ({
    datePicker: [],
  }),
  actions: {
    setDatePicker(date) {
      this.datePicker = [];
      if (localStorage.getItem("dateSelect")) {
        let dates = localStorage.getItem("dateSelect");
        let a = dates.split(",");
        this.datePicker = a.map((item) => new Date(item));
      } else this.datePicker = date;
    },
  },
  getters: {
    showDatePicker: (state) => {
      return state.datePicker;
    },
  },
});
