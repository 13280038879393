<template>
  <el-config-provider :locale="locale">
    <router-view> </router-view>
  </el-config-provider>
</template>

<script setup>
import { computed } from "vue";
import enLocale from "element-plus/lib/locale/lang/en";
import viLocale from "element-plus/lib/locale/lang/vi";
import { langWeb } from "@/stores/lang";
const lang_web = langWeb();
const locale = computed(() => {
  const langState = lang_web.showLang;
  const local = langState === "vi" ? viLocale : enLocale;
  return local;
});
</script>
