export default [
  {
    path: "/home",
    name: "default",
    meta: {
      title: "Trang chủ",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
        ],
      },
    },
    component: () => import("../views/dashboard/dashboard.vue"),
  },

  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: {
      title: "Coming Soon",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Coming soon ",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Coming soon ",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/pages/coming-soon"),
  },
  {
    path: "/no-permission",
    name: "No permission",
    component: () => import("../views/pages/no-permission.vue"),
    meta: {
      title: "No permission",
      allowAccess: ["*"],
      page: "auth",
      breadcrumb: {
        vi: [
          {
            text: "",
            href: "/",
          },
        ],
        en: [
          {
            text: "",
            href: "/",
          },
        ],
      },
    },
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/pages/404.vue"),
    meta: {
      title: "Error",
      allowAccess: ["*"],
      page: "auth",
      breadcrumb: {
        vi: [
          {
            text: "",
            href: "/",
          },
        ],
        en: [
          {
            text: "",
            href: "/",
          },
        ],
      },
    },
  },
  {
    path: "/session-time-out",
    name: "session-time-out",
    meta: {
      title: "Session Time Out",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
    },
    component: () => import("../views/session-time-out/session-time-out.vue"),
  },
  {
    path: "/service-health",
    name: "ServiceHealth",
    meta: {
      title: "Service health",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Service Health",
            href: "/service-health",
          },
        ],
        en: [
          {
            text: "Service Health",
            href: "/service-health",
          },
        ],
      },
    },
    component: () => import("../views/service-health/service-health.vue"),
  },

  // khai báo thêm menu
  {
    path: "/services",
    name: "DanhSachDichVu",
    meta: {
      title: "Services",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Dịch vụ",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Service",
            href: "",
          },
        ],
      },
    },
  },
  {
    path: "/services/:id",
    name: "DichVu",
    meta: {
      title: "Services",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Dịch vụ",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Service",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/dich-vu/dashboard-dich-vu.vue"),
  },
  {
    path: "/dashboard/:id",
    name: "Dynamic",
    meta: {
      title: "Dynamic",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
        ],
      },
    },
    component: () => import("../views/dynamic/dynamic.vue"),
  },
  {
    path: "/customers",
    name: "KhachHang",
    meta: {
      title: "Customers",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Khách hàng",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Customer",
            href: "",
          },
        ],
      },
    },
  },
  {
    path: "/customers/general",
    name: "GeneralKhachHang",
    meta: {
      title: "General Customer",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Khách hàng",
            href: "",
          },
          {
            text: "Bảng tổng hợp",
            href: "/customers/general",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Customer",
            href: "",
          },
          {
            text: "General",
            href: "/customers/general",
          },
        ],
      },
    },
    component: () =>
      import("../views/khach-hang/general-khach-hang/general-khach-hang.vue"),
  },
  {
    path: "/customers/session",
    name: "SessionDichVu",
    meta: {
      title: "Danh sách session",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Khách hàng",
            href: "",
          },
          {
            text: "Danh sách session",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Customer",
            href: "",
          },
          {
            text: "List session",
            href: "",
          },
        ],
      },
    },
    params: true,
    component: () =>
      import("../views/khach-hang/session-theo-dich-vu/danh-sach-session.vue"),
  },
  {
    path: "/customers/dashboard",
    name: "DashboardKhachHang",
    meta: {
      title: "Dashboard Customer",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Khách hàng",
            href: "",
          },
          {
            text: "Dashboard",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Customer",
            href: "",
          },
          {
            text: "Dashboard",
            href: "",
          },
        ],
      },
    },
    component: () =>
      import(
        "../views/khach-hang/dashboard-khach-hang/dashboard-khach-hang.vue"
      ),
  },
  // menu thay thees usecase
  {
    path: "/usecases",
    name: "Usecases",
    meta: {
      title: "Usecases",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Usecases",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Usecases",
            href: "",
          },
        ],
      },
    },
  },
  {
    path: "/usecases/tong_hop/:id",
    name: "UsecaseGeneral",
    meta: {
      title: "Usecases",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "UC Customer",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "UC Customer",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/usecase/UseCaseGeneral.vue"),
  },

  // ----->
  {
    path: "/usecases/uc53/:id",
    name: "Usecases53",
    meta: {
      title: "Usecases",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Usecases 5.3",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Usecases",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/usecase/UseCase53.vue"),
  },
  {
    path: "/usecases/uc531/:id",
    name: "Usecases531",
    meta: {
      title: "Usecases",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Usecases 5.3.1",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Usecases 5.3.1",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/usecase/UseCaseDetailFromUC53.vue"),
  },
  {
    path: "/usecases/uc621/:id",
    name: "Usecases621",
    meta: {
      title: "Usecases",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
        ],
      },
    },
    component: () => import("../views/usecase/UseCaseDetailUC621.vue"),
  },
  {
    path: "/usecases/uc622/:id",
    name: "Usecases622",
    meta: {
      title: "Usecases",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "UC Server",
            href: "",
          },
          {
            text: "Usecases 6.2.2",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "UC Server",
            href: "",
          },
          {
            text: "Usecases 6.2.2",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/usecase/UseCaseDetailUC622.vue"),
  },
  {
    path: "/usecases/uc62/:id",
    name: "Usecases62",
    meta: {
      title: "Usecases",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "UC Server",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "UC Server",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/usecase/UseCase62.vue"),
  },
  {
    path: "/customers/dashboard-timespan",
    name: "DichVuTheoKhungGio",
    meta: {
      title: "Dashboard timespan",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Khách hàng",
            href: "",
          },
          {
            text: "Dashboard theo khung giờ",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Customer",
            href: "",
          },
          {
            text: "Dashboard timespan",
            href: "",
          },
        ],
      },
    },
    component: () =>
      import(
        "../views/khach-hang/dich-vu-theo-khung-gio/dich-vu-theo-khung-gio.vue"
      ),
  },
  {
    path: "/guide",
    name: "HuongDanSuDung",
    meta: {
      title: "Guide",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Hướng dẫn sử dụng",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Guide",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/huong-dan-su-dung/hdsd.vue"),
  },
  {
    path: "/commingsoon",
    name: "Commingsoon",
    meta: {
      title: "Commingsoon",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Commingsoon",
            href: "",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
          {
            text: "Commingsoon",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/pages/coming-soon.vue"),
  },
  {
    path: "/table-screen/:id",
    name: "TableScreen",
    meta: {
      title: "TableScreen",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
        ],
        en: [
          {
            text: localStorage.probeName,
            href: "/home",
          },
        ],
      },
    },
    component: () => import("../views/table-screen/tableScreenDetail.vue"),
  },
];
