import { defineStore } from "pinia";

export const timeRange = defineStore({
  id: "timeRange",
  state: () => ({
    timeRange: 0,
  }),
  actions: {
    setTimeRange(date) {
      this.timeRange = date;
    },
  },
  getters: {
    showTimeRange: (state) => {
      return state.timeRange;
    },
  },
});
