import { defineStore } from "pinia";

export const isHaveDataDashboard = defineStore({
  id: "isHaveDataDashboard",
  state: () => ({
    isHaveData: false,
    isDownloadData: false,
  }),
  actions: {
    haveData() {
      this.isHaveData = true;
    },
    noData() {
      this.isHaveData = false;
    },
    downloadData() {
      this.isDownloadData = true;
    },
    stopDownloadData() {
      this.isDownloadData = false;
    },
  },
  getters: {
    showIsHaveDataDashboard: (state) => {
      if (state.isHaveData == true) return true;
      return false;
    },
    showDownloadData: (state) => {
      if (state.isDownloadData == true) return true;
      return false;
    },
  },
});
