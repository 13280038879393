//import mushroom, {defineAsyncResource} from "mushroomjs";
Object.defineProperty(exports, "__esModule", { value: true });
var mushroom_1 = require("mushroomjs");
var mushroom = mushroom_1.default;
var defineAsyncResource = mushroom_1.defineAsyncResource;

//export * from "mushroomjs";
for (var m in mushroom_1) exports[m] = mushroom_1[m];

//import * as AuthExtension from "mushroomjs-auth";
var AuthExtension = require("mushroomjs-auth");
//import * as FileExtension from "mushroomjs-file";
var FileExtension = require("mushroomjs-file");
//export const extensions = {
var extensions = {
    Auth: AuthExtension,
    File: FileExtension
};
exports.extensions = extensions;

"use strict";

defineAsyncResource({name:"card",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"card_item",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"card_item_filter",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"probe_data",actions:{"_raw_http_method_getDatasource":{},"_raw_http_method_getSumDataCustomer":{},"_raw_http_method_getSessionDataCustomer":{},"_raw_http_method_getCustomers":{},"_raw_http_method_getDefaultDate":{},"_raw_http_method_getVlanIds":{},"_raw_http_method_getServiceHealthData":{},"_raw_http_method_getServiceHealthChart":{}},views:{}});
defineAsyncResource({name:"request_download_dashboard",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"_raw_http_method_downloadFile":{}},views:{}});
defineAsyncResource({name:"icon",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"time_range",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"service_health",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"createOne":{},"updatePartially":{},"deleteOne":{}},views:{}});
defineAsyncResource({name:"service_health_kpi",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"table_config",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"quality_level",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"customer_summary",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"user",actions:{"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"profile",actions:{"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"group",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"user_group",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"department",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"service",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"usecase",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"table_screen",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"dynamic_dashboard",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"dashboard_popup",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"menu",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true},"_raw_http_method_log_view_menu":{}},views:{"get_tree":{}}});
defineAsyncResource({name:"probe",actions:{"_raw_http_method_getProbeInformation":{}},views:{}});
defineAsyncResource({name:"system_config",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"user_config",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"province",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"district",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});
defineAsyncResource({name:"commune",actions:{"findMany":{"clientCache":true,"includeTotal":true},"findById":{"clientCache":true}},views:{}});

mushroom.$using("https://cem-probe-api.test2.siten.vn/api/cem-probe/v1/");

AuthExtension.useAuth(AuthExtension.AuthLocalStorage, mushroom_1.default);
FileExtension.useFile(mushroom_1.default);

//export default mushroom;
exports.default = mushroom;