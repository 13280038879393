
import { defineStore } from 'pinia'


export const langWeb = defineStore({
    id: 'langWeb',
    state: () => ({
        lang: localStorage.getItem('language') ?? 'vi'
    }),
    actions: {
        changeLangVI() {
            this.lang = 'vi'
        },
        changeLangEN() {
            this.lang = 'en'
            console.log("set ngôn ngữ en");
        }
    },
    getters: {
        showLang: (state) => {
            if (state.lang == 'vi') return 'vi'
            return 'en'
        }
    }
})

